import React, { useRef } from 'react';
import { YMaps, Map, Placemark, AnyObject } from 'react-yandex-maps';

import styles from './styles.module.css';

export interface Props {
    coordinate: number[];
    className?: string;
    onCoordinateUpdate?: any;
}

export const DEFAULT_MAP_CENTER = [37.6424772, 55.7610334];

const YandexMap: React.FC<Props> = ({ coordinate, onCoordinateUpdate }) => {
    const willMount = useRef(true);
    return (
        <YMaps query={{ apikey: process.env.REACT_APP_YANDEX_API_KEY }}>
            <Map
                state={{
                    center:
                        [coordinate[1], coordinate[0]] || DEFAULT_MAP_CENTER,
                    zoom: 15,
                }}
                className={styles.map}
            >
                <Placemark
                    geometry={[coordinate[1], coordinate[0]]}
                    options={{ draggable: true }}
                    instanceRef={(ref: AnyObject) => {
                        if (ref && willMount.current) {
                            ref.events.add('dragend', (e: AnyObject) => {
                                const newCoords = e
                                    .get('target')
                                    .geometry.getCoordinates();
                                onCoordinateUpdate([
                                    newCoords[1],
                                    newCoords[0],
                                ]);
                                willMount.current = false;
                            });
                        }
                    }}
                />
            </Map>
        </YMaps>
    );
};

export default YandexMap;
