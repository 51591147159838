import { IGroupWorkoutReservationNew } from '@t/reservation';

export const FETCH_RESERVATIONS_REQUEST = 'FETCH_RESERVATIONS_REQUEST';
export const FETCH_RESERVATIONS_SUCCESS = 'FETCH_RESERVATIONS_SUCCESS';
export const FETCH_RESERVATIONS_ERROR = 'FETCH_RESERVATIONS_ERROR';

export const FETCH_SCROLL_RESERVATIONS_REQUEST =
    'FETCH_SCROLL_RESERVATIONS_REQUEST';
export const FETCH_SCROLL_RESERVATIONS_SUCCESS =
    'FETCH_SCROLL_RESERVATIONS_SUCCESS';
export const FETCH_SCROLL_RESERVATIONS_ERROR =
    'FETCH_SCROLL_RESERVATIONS_ERROR';

export const FETCH_RESERVATION_REQUEST = 'FETCH_RESERVATION_REQUEST';
export const FETCH_RESERVATION_SUCCESS = 'FETCH_RESERVATION_SUCCESS';
export const FETCH_RESERVATION_ERROR = 'FETCH_RESERVATION_ERROR';

export const FETCH_USER_RESERVATION_REQUEST = 'FETCH_RESERVATION_REQUEST';
export const FETCH_USER_RESERVATION_SUCCESS = 'FETCH_RESERVATION_SUCCESS';
export const FETCH_USER_RESERVATION_ERROR = 'FETCH_RESERVATION_ERROR';

export const UPDATE_RESERVATION_REQUEST = 'UPDATE_RESERVATION_REQUEST';
export const UPDATE_RESERVATION_SUCCESS = 'UPDATE_RESERVATION_SUCCESS';
export const UPDATE_RESERVATION_ERROR = 'UPDATE_RESERVATION_ERROR';

export const FETCH_GROUP_WORKOUT_RESERVATIONS_REQUEST =
    'FETCH_GROUP_WORKOUT_RESERVATIONS_REQUEST';
export const FETCH_GROUP_WORKOUT_RESERVATIONS_SUCCESS =
    'FETCH_GROUP_WORKOUT_RESERVATIONS_SUCCESS';
export const FETCH_GROUP_WORKOUT_RESERVATIONS_ERROR =
    'FETCH_GROUP_WORKOUT_RESERVATIONS_ERROR';

export const FETCH_RESERVATIONS_FOR_REPORT_REQUEST =
    'FETCH_RESERVATIONS_FOR_REPORT_REQUEST';
export const FETCH_RESERVATIONS_FOR_REPORT_SUCCESS =
    'FETCH_RESERVATIONS_FOR_REPORT_SUCCESS';
export const FETCH_RESERVATIONS_FOR_REPORT_ERROR =
    'FETCH_RESERVATIONS_FOR_REPORT_ERROR';

export interface ReservationsState {
    ids: string[];
    byId: {
        [id: string]: IGroupWorkoutReservationNew;
    };
    lastUpdated: number;
}
