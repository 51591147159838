import React, { useState, useCallback, useEffect } from 'react';
import cogoToast from 'cogo-toast';
import { useDispatch } from 'react-redux';
import { WrappedFieldProps } from 'redux-form';
import { useDebounce } from 'hooks/debounce';

import {
    fetchAddressByLocation,
    fetchSuggestionsByAddress,
} from '@redux/modules/gyms/actions';
import { FeatureMemberEntity } from '@redux/modules/gyms/types';
import { AsyncDispatch } from '@redux/types';

import YandexMap from '../YandexMap';
import styles from './styles.module.css';

export interface Props {
    label: string;
    name: string;
    placeholder?: string;
    required?: boolean;
    disabled?: boolean;
}

const GeoInput: React.FC<Props & WrappedFieldProps> = ({
    label,
    name,
    input,
    placeholder,
    required,
    disabled,
}) => {
    const dispatch = useDispatch<AsyncDispatch>();
    const [address, updateAddress] = useState<string>(
        input.value?.address || ''
    );
    const [suggestions, updateSuggestions] = useState<FeatureMemberEntity[]>(
        []
    );
    const [coordinates, updateCoordinates] = useState<number[]>(
        input.value?.position
            ? [input.value.position.lng, input.value.position.lat]
            : []
    );
    const [dropdown, setDropdown] = useState<boolean>(false);
    const debounced = useDebounce(address);

    useEffect(() => {
        if (!debounced || debounced.length < 3) {
            return;
        }
        dispatch(fetchSuggestionsByAddress(debounced))
            .then((response) => {
                updateSuggestions(response.GeoObjectCollection.featureMember);
            })
            .catch(() => {
                cogoToast.error('Ошибка при загрузке адреса', {
                    position: 'top-right',
                    hideAfter: 4,
                });
            });
    }, [debounced]);

    const onPickSuggestion = useCallback((suggestion) => {
        updateAddress(suggestion.metaDataProperty.GeocoderMetaData.text);
        updateSuggestions([]);
        updateCoordinates(suggestion.Point.pos.split(' '));
        setDropdown(false);
        input.onChange({
            position: {
                lat: Number(suggestion.Point.pos.split(' ')[1]),
                lng: Number(suggestion.Point.pos.split(' ')[0]),
            },
            address: suggestion.metaDataProperty.GeocoderMetaData.text,
            shortAddress: suggestion.name,
        });
    }, []);

    const handleCoordinateChange = useCallback(
        (position) => {
            dispatch(fetchAddressByLocation(position))
                .then((response) => {
                    setDropdown(true);
                    updateSuggestions([
                        response.GeoObjectCollection.featureMember[0],
                    ]);
                })
                .catch(() => {
                    cogoToast.error('Ошибка при загрузке адреса', {
                        position: 'top-right',
                        hideAfter: 4,
                    });
                });
        },
        [input]
    );

    return (
        <div className={styles.wrapper}>
            <label htmlFor={name} className={styles.label}>
                {label}
            </label>
            <input
                value={address}
                onChange={(e) => {
                    updateAddress(e.target.value);
                    setDropdown(true);
                }}
                name={name}
                placeholder={placeholder}
                required={required}
                disabled={disabled}
                className={styles.input}
            />
            <div className={styles.dropdown}>
                {dropdown && (
                    <ul className={styles.dropdownList}>
                        {(suggestions || []).map(
                            (suggestion: FeatureMemberEntity) => {
                                const onClick = () =>
                                    onPickSuggestion(suggestion.GeoObject);
                                return (
                                    <li key={suggestion.GeoObject.Point.pos}>
                                        <button
                                            type="button"
                                            className={styles.suggestion}
                                            onClick={onClick}
                                        >
                                            <span>
                                                {
                                                    suggestion.GeoObject
                                                        .metaDataProperty
                                                        .GeocoderMetaData.text
                                                }
                                            </span>
                                        </button>
                                    </li>
                                );
                            }
                        )}
                    </ul>
                )}
            </div>
            {!!input.value?.position && (
                <YandexMap
                    coordinate={coordinates}
                    className={styles.mapWrapper}
                    onCoordinateUpdate={handleCoordinateChange}
                />
            )}
        </div>
    );
};

export default GeoInput;
